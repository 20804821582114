<template>
  <div>
    <div class="light-bg">
      <b-container
        class="care-container py-2 d-flex flex-row align-items-center justify-content-between"
      >
        <SelectLanguage class="language-selector" />
        <div class="search-form">
          <b-form-input
            class="searchbar"
            placeholder="searchbar"
            type="search"
            v-model="filterQuery"
            ><b-icon class="search" icon="search"></b-icon>
          </b-form-input>
          <button class="d-flex align-items-center search">
            <!-- <img src="./../assets/magnifying-glass.svg" />
              -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.4 50.1">
              <path
                fill="currentColor"
                d="M36.9,45.5L27.3,30c-0.2-0.3-0.5-0.6-0.7-0.8c3.8-3,6.3-7.6,6.3-12.9c0-9-7.3-16.4-16.4-16.4
	C7.3,0,0,7.3,0,16.4c0,9,7.3,16.4,16.4,16.4c1.9,0,3.7-0.3,5.3-0.9c0,0.5,0.2,0.9,0.4,1.4l9.7,15.4c0.6,0.9,1.5,1.4,2.5,1.4
	c0.5,0,1.1-0.1,1.6-0.5C37.4,48.7,37.8,46.9,36.9,45.5z M2,16.4C2,8.5,8.5,2,16.4,2s14.4,6.5,14.4,14.4c0,7.9-6.5,14.4-14.4,14.4
	S2,24.3,2,16.4z"
              />
              <path
                fill="currentColor"
                d="M26.9,11.8c-1.1-3-4.2-4.4-4.3-4.5c-0.6-0.3-1.3,0-1.5,0.6c-0.3,0.6,0,1.3,0.6,1.5c0,0,2.3,1,3.1,3.2
	c0.5,1.3,0.3,2.7-0.5,4.3c-0.3,0.6,0,1.3,0.5,1.5c0.2,0.1,0.3,0.1,0.5,0.1c0.4,0,0.8-0.2,1-0.7C27.4,15.7,27.6,13.6,26.9,11.8z"
              />
            </svg>
          </button>
        </div>
      </b-container>
    </div>

    <div class="care-container">
      <CategoryFilter
        :items="categories"
        :active="activeCategory"
        @change="handleNavChange"
      />
      <div class="card-drawer">
        <span class="results"
          >{{
            filteredProducts.length === products.length
              ? 'all'
              : filteredProducts.length
          }}
          results</span
        >
        <ul v-if="filteredProducts.length" class="card-container mt-1">
          <li
            class="product"
            v-for="(product, index) in filteredProducts"
            :id="product.product_id"
            :key="product.product_id"
          >
            <SelectCard
              :card="product"
              @click.prevent="openForm(product.product_id)"
              :index="index"
            />
          </li>
        </ul>
        <span v-else class="d-flex align-self-center text-center mt-1 no-results"
          >There are no available results.</span
        >
      </div>
    </div>

    <Footer></Footer>

    <b-modal
      v-model="showConfirmation"
      ok-only
      title-html="<h2>The card is on its way!</h2><h6>Thanks for showing our customers just how much we care.</h6>"
      no-close-on-esc
      no-close-on-backdrop
      size="xl"
    >
      <b-container class="order-confirmation">
        <b-row>
          <b-col cols="4">
            <img class="img-fluid" :src="productImage" alt="" />
          </b-col>
          <b-col class="text-left white-space:pre-line">
            <b-row>
              <b-col>
                <h4>Delivery Address:</h4>
                {{ orderConfirmationDetails.dAddline1 }} <br />
                {{ orderConfirmationDetails.dAddline2 }} <br />
                {{ orderConfirmationDetails.dAddline3 }}
                <br v-if="orderConfirmationDetails.dAddline3" />
                {{ orderConfirmationDetails.dAddline4 }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4>Mail Date:</h4>
                {{ orderConfirmationDetails.shipDate }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4>Confirmation:</h4>
                {{ orderConfirmationDetails.orderNo }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <h4>Description:</h4>
                {{ orderConfirmationDetails.prodDesc }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      modal-class="modal-preview"
      id="modal-preview"
      v-model="showPreview"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      ok-title="Send"
      cancel-title="Edit"
      size="xl"
      @ok="onSubmit"
    >
      <div class="preview-header">
        <div class="d-flex">
          <h2 class="mb-0 ml-2">
            Let's double check that everything looks good
          </h2>
        </div>
        <button
          class="close"
          aria-label="close"
          @click="$bvModal.hide('modal-preview')"
        >
          <!-- <img src="./../assets/x.svg" /> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="21"
            viewBox="0 0 21 21"
          >
            <path
              fill="currentColor"
              id="x"
              d="M26,7.1,23.9,5l-8.4,8.4L7.1,5,5,7.1l8.4,8.4L5,23.9,7.1,26l8.4-8.4L23.9,26,26,23.9l-8.4-8.4Z"
              transform="translate(-5 -5)"
              fill-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="image-container">
        <div class="image-preview" v-for="image in previewImages" :key="image">
          <img
            style="
              object-fit: contain;
              object-position: center center;
              width: 100%;
              height: 100%;
            "
            :src="image"
          />
        </div>
      </div>
    </b-modal>

    <b-modal
      modal-class="modal-fullscreen"
      id="modal-fullscreen"
      hide-header
      hide-footer
      size="xl"
      fade
      v-model="showForm"
      no-close-on-esc
      no-close-on-backdrop
      @hidden="onOrderFormHidden"
    >
      <!-- Sender Template -->
      <!-- @submit.prevent="onSubmit" -->
      <form  @submit.prevent="onSubmit" class="sender-form" >
        <div class="sender-header">
          <div class="d-flex">
            <img src="./../assets/envelope-front.svg" />
            <h2 class="mb-0 ml-2">Address the envelope</h2>
          </div>
          <button
            class="close"
            aria-label="close"
            @click="$bvModal.hide('modal-fullscreen')"
          >
            <!-- <img src="./../assets/x.svg" /> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
            >
              <path
                fill="currentColor"
                id="x"
                d="M26,7.1,23.9,5l-8.4,8.4L7.1,5,5,7.1l8.4,8.4L5,23.9,7.1,26l8.4-8.4L23.9,26,26,23.9l-8.4-8.4Z"
                transform="translate(-5 -5)"
                fill-rule="evenodd"
              />
            </svg>
          </button>
        </div>
        <!--  address envelope grid -->
        <div  class="sender-grid" v-if="hasSenderProfile">
          <!-- sender-profile -->
          <section class="profile" v-if="senderProfileOptions.length > 0">
            <b-form-group>
              <label for="senderProfile">Sender Profile</label>
              <b-form-select
                id="senderProfile"
                name="senderProfile"
                v-model="senderProfile"
                placeholder="select"
                :options="senderProfileOptions"
                :disabled="senderProfileOptions.length == 1"
              >
              </b-form-select>
            </b-form-group>
          </section>
          <!-- Customer Name -->
          <section class="customer-num" v-if="hasSenderProfile">
            <b-form-group v-if="accountNumberRequiresValidation">
              <label for="accountNumber">Customer Number</label>
              <b-input-group>
                <b-form-input
                  id="accountNumber"
                  v-model="$v.form.accountNumber.$model"
                  :disabled="isDisabledField('accountNumber')"
                  :state="validateState('accountNumber')"
                  aria-describedby="accountNumber-live-feedback"
                >
                </b-form-input>
                <!-- {{ $v.form.accountNumber.$model }} -->
                <template v-slot:append>
                  <b-input-group-text>
                    <b-icon icon="person-fill"></b-icon>
                  </b-input-group-text>
                </template>
              </b-input-group>
              <b-form-invalid-feedback
                id="accountNumber-live-feedback"
                :class="
                  validateState('accountNumber') === false ? 'd-block' : ''
                "
              >
                {{ accountNumberErrorMessage }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- first name -->
          <section class="first-name" v-if="hasSenderProfile">
            <b-form-group>
              <label for="firstName">First name</label>
              <b-form-input
                id="firstName"
                name="firstName"
                v-model="$v.form.firstName.$model"
                :state="validateState('firstName')"
                aria-describedby="firstName-live-feedback"
                autocomplete="given-name"
                :disabled="isDisabledField('firstName')"
              >
              </b-form-input>
              <b-form-invalid-feedback id="firstName-live-feedback">
                {{ firstNameFeedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- last name -->
          <section class="last-name" v-if="hasSenderProfile">
            <b-form-group>
              <label for="lastName">Last name</label>
              <b-form-input
                id="lastName"
                name="lastName"
                v-model="$v.form.lastName.$model"
                :state="validateState('lastName')"
                aria-describedby="lastName-live-feedback"
                autocomplete="family-name"
                :disabled="isDisabledField('lastName')"
              >
              </b-form-input>
              <b-form-invalid-feedback id="lastName-live-feedback">
                {{ lastNameFeedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- address one -->
          <section class="address-one" v-if="hasSenderProfile">
            <b-form-group>
              <label for="address1">Address</label>
              <b-form-input
                id="address1"
                name="address1"
                v-model="$v.form.address1.$model"
                :state="validateState('address1')"
                aria-describedby="address1-live-feedback"
                autocomplete="address-line1"
                :disabled="isDisabledField('address1')"
              >
              </b-form-input>
              <b-form-invalid-feedback id="address1-live-feedback">
                {{ addressFeedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- address two -->
          <section class="address-two" v-if="hasSenderProfile">
            <b-form-group>
              <label for="address2">Apartment, suite, etc.</label>
              <b-form-input
                id="address2"
                name="address2"
                v-model="$v.form.address2.$model"
                :state="validateState('address2')"
                aria-describedby="address2-live-feedback"
                autocomplete="address-line2"
                :disabled="isDisabledField('address2')"
              >
              </b-form-input>
              <b-form-invalid-feedback id="address2-live-feedback">
                {{ address2Feedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- city -->
          <section class="city" v-if="hasSenderProfile">
            <b-form-group>
              <label for="city">City</label>
              <b-form-input
                id="city"
                name="city"
                v-model="$v.form.city.$model"
                :state="validateState('city')"
                aria-describedby="city-live-feedback"
                autocomplete="address-level-2"
                :disabled="isDisabledField('city')"
              >
              </b-form-input>
              <b-form-invalid-feedback id="city-live-feedback">
                {{ cityFeedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- state -->
          <section class="state" v-if="hasSenderProfile">
            <b-form-group>
              <label for="state">State</label>
              <b-form-select
                id="state"
                name="state"
                v-model="$v.form.state.$model"
                placeholder="select"
                :state="validateState('state')"
                :options="stateOptions"
                aria-describedby="state-live-feedback"
                autocomplete="address-level-1"
                :disabled="isDisabledField('state')"
              >
              </b-form-select>
              <b-form-invalid-feedback id="state-live-feedback">
                Please select a state.
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
          <!-- zipcode postalcode -->
          <section class="postal-code" v-if="hasSenderProfile">
            <b-form-group>
              <label for="postal-code">Zip code</label>
              <b-form-input
                id="postal-code"
                name="postal-code"
                v-model="$v.form.zipCode.$model"
                :state="validateState('zipCode')"
                aria-describedby="zipCode-live-feedback"
                autocomplete="postal-code"
                @input="formatZipcode"
                :disabled="isDisabledField('zipCode')"
              >
              </b-form-input>
              <b-form-invalid-feedback id="zipCode-live-feedback">
                {{ zipCodeFeedback }}
              </b-form-invalid-feedback>
            </b-form-group>
          </section>
        </div>
        <!-- personalize header -->
        <div class="header" v-if="hasSenderProfile">
          <img src="./../assets/card-folded-pencil.svg" />
          <h2 class="mb-0 ml-2">Personalize The Card</h2>
        </div>
        <h3 class="mb-0" v-if="hasSenderProfile">Select a sentiment</h3>
        <div
          class="personalize-grid"
          v-if="this.getSentiments.length > 1 && hasSenderProfile"
        >
          <template v-for="option in sentimentOptions">
            <label
              :for="option.value"
              v-if="option.value"
              :key="option.value"
              :class="[form.sentiment === option.value && 'active']"
            >
              <input
                type="radio"
                :id="option.value"
                :value="option.value"
                v-model="$v.form.sentiment.$model"
                :state="'sentiment'"
              />
              {{ option.text }}
            </label>
          </template>
        </div>
        <div class="invalid-sentiment" v-if="hasSenderProfile">
          <b-form-invalid-feedback
            :state="showForm && validateState('sentiment')"
            id="sentiment-live-feedback"
          >
            Please select a sentiment.
          </b-form-invalid-feedback>
        </div>

        <h3 class="mb-0" v-if="hasSenderProfile">
          Choose a personal closing or type a message
        </h3>
        <div
          class="personalize-grid"
          v-if="presetClosingOptions.length > 1 && hasSenderProfile"
        >
          <template v-for="option in presetClosingOptions">
            <label
              :for="option.value"
              v-if="option.value"
              :key="option.value"
              :class="[form.closing === option.text && 'active']"
            >
              <input
                type="radio"
                :id="option.value"
                :value="option.text"
                v-model="form.closing"
              />

              {{ option.text }}
            </label>
          </template>
        </div>
        <!-- personal closing -->
        <div
          class="personal-closing"
          v-if="showPersonalClosing && hasSenderProfile"
        >
          <b-form-group v-bind:style="{ marginBottom: '0px' }">
            <b-form-textarea
              id="closing"
              name="closing"
              v-model="$v.form.closing.$model"
              :state="validateState('closing')"
              aria-describedby="closing-live-feedback"
              placeholder="Type your message here"
              :disabled="disablePersonalClosing"
              maxlength="270"
            >
            </b-form-textarea>
            <span class="limiter">{{ charactersRemaining }}</span>
            <b-form-invalid-feedback id="closing-live-feedback">
              {{ closingFeedback }}
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- </b-col> -->
        </div>

        <div class="send-date">
          <label
            for="sendDate"
            style="margin: 5px 0px"
            v-if="showSendSchedule"
            :class="[(showSendSchedule && 'display') || 'hidden']"
            >Future ship date must be after customer's move in date</label
          >
          <b-form-datepicker
            v-if="showSendSchedule"
            :class="[showSendSchedule && 'display']"
            id="sendDate"
            :date-disabled-fn="dateDisabled"
            :min="minDate"
            :max="maxDate"
            v-model="$v.form.sendDate.$model"
            :disabled="isDisabledField('sendDate')"
          />

          <span v-if="$v.form.sendDate.$model && hasSenderProfile">
            Your selected shipping date is {{ $v.form.sendDate.$model }}.</span
          >
        </div>

        <div class="account-error" v-if="!hasSenderProfile">
          <h4 class="bg-white text-danger">Account Error</h4>
          <p>
            Your user account is missing a sender profile. At least one sender
            profile must be assigned to send or preview a card. Please contact
            your Hallmark program administrator to update your account.
          </p>
        </div>

        <div class="sender-buttons">
          <button
            :disabled="!hasSenderProfile"
            variant="secondary"
            block
            @click.prevent="openPreview"
          >
            Preview
          </button>

          <button
            @click="showSendSchedule = !showSendSchedule"
            :disabled="!hasSenderProfile"
            :class="[showSendSchedule && 'active']"
          >
            Schedule
          </button>

          <button
            :disabled="!hasSenderProfile"
            variant="primary"
            block
            type="submit"
          >
            Send Now
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable no-console */
import SelectCard from '@/components/SelectCard.vue';
import SelectLanguage from '@/components/SelectLanguage.vue';
import CategoryFilter from '@/components/CategoryFilter.vue';
import Footer from '@/layouts/Footer.vue';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import Cookies from 'js-cookie';
import ProfanityFilter from 'bad-words';
import store from '../store/index';
const profanityFilter = new ProfanityFilter();
//removal of words that clients need
profanityFilter.removeWords(
  'dyke',
  'dick',
  'cox',
  'wang',
  'schaffer',
  'gaylord',
  'god',
  'god bless',
  'coon'
);

const numbersOnlyRegex = new RegExp(/^\d+$/);
const hyphenZipCodeStartRegex = new RegExp(/^\d{5}-\d+$/);
const hyphenZipCodeRegex = new RegExp(/^\d{5}-\d{4}$/);

const maxBaskets = 1;

const lineMaxLength = 55;
const cityMaxLength = lineMaxLength - ' XX, XXXXX-XXXX'.length;

export default {
  name: 'Home',
  components: {
    CategoryFilter,
    SelectCard,
    SelectLanguage,
    Footer,
  },
  mixins: [validationMixin],
  data() {
    return {
      errors: [],
      showForm: false,
      showPreview: false,
      showConfirmation: false,
      showSendSchedule: false,
      previewImages: [],
      filterQuery: '',
      presetClosing: null,
      disabledFields: [],
      senderProfile: null,
      storeRecipientData: false,
      minDate: '',
      maxDate: '',
      envImage: '/images/envelope.png',
      stateOptions: [
        { value: '', text: 'State/Province' },
        { value: 'AL', text: 'Alabama' },
        { value: 'AK', text: 'Alaska' },
        { value: 'AZ', text: 'Arizona' },
        { value: 'AR', text: 'Arkansas' },
        { value: 'CA', text: 'California' },
        { value: 'CO', text: 'Colorado' },
        { value: 'CT', text: 'Connecticut' },
        { value: 'DE', text: 'Delaware' },
        { value: 'FL', text: 'Florida' },
        { value: 'GA', text: 'Georgia' },
        { value: 'HI', text: 'Hawaii' },
        { value: 'ID', text: 'Idaho' },
        { value: 'IL', text: 'Illinois' },
        { value: 'IN', text: 'Indiana' },
        { value: 'IA', text: 'Iowa' },
        { value: 'KS', text: 'Kansas' },
        { value: 'KY', text: 'Kentucky' },
        { value: 'LA', text: 'Louisiana' },
        { value: 'ME', text: 'Maine' },
        { value: 'MD', text: 'Maryland' },
        { value: 'MA', text: 'Massachusetts' },
        { value: 'MI', text: 'Michigan' },
        { value: 'MN', text: 'Minnesota' },
        { value: 'MO', text: 'Missouri' },
        { value: 'MS', text: 'Mississippi' },
        { value: 'MT', text: 'Montana' },
        { value: 'NE', text: 'Nebraska' },
        { value: 'NV', text: 'Nevada' },
        { value: 'NH', text: 'New Hampshire' },
        { value: 'NJ', text: 'New Jersey' },
        { value: 'NM', text: 'New Mexico' },
        { value: 'NY', text: 'New York' },
        { value: 'NC', text: 'North Carolina' },
        { value: 'ND', text: 'North Dakota' },
        { value: 'OH', text: 'Ohio' },
        { value: 'OK', text: 'Oklahoma' },
        { value: 'OR', text: 'Oregon' },
        { value: 'PA', text: 'Pennsylvania' },
        { value: 'RI', text: 'Rhode Island' },
        { value: 'SC', text: 'South Carolina' },
        { value: 'SD', text: 'South Dakota' },
        { value: 'TN', text: 'Tennessee' },
        { value: 'TX', text: 'Texas' },
        { value: 'UT', text: 'Utah' },
        { value: 'VT', text: 'Vermont' },
        { value: 'VA', text: 'Virginia' },
        { value: 'WA', text: 'Washington' },
        { value: 'DC', text: 'Washington DC' },
        { value: 'WV', text: 'West Virginia' },
        { value: 'WI', text: 'Wisconsin' },
        { value: 'WY', text: 'Wyoming' },
        { value: 'GU', text: 'Guam' },
        { value: 'PR', text: 'Puerto Rico' },
        { value: 'VI', text: 'Virgin Islands' },
        { value: 'MP', text: 'Northern Mariana Islands' },
        { value: 'AS', text: 'American Samoa' },
        { value: 'MH', text: 'Marshall Islands' },
        { value: 'PW', text: 'Palau' },
        {
          value: 'AE',
          text: 'AE - Armed Forces Europe, the Middle East, Canada',
        },
        { value: 'AP', text: 'AP - Armed Forces Pacific' },
        { value: 'AA', text: 'AA - Armed Forces America (except Canada)' },
      ],
      form: {
        accountNumber: '',
        lastName: '',
        firstName: '',
        address1: '',
        address2: '',
        sentiment: null,
        closing: null,
        city: '',
        state: '',
        zipCode: '',
        sendDate: '',
      },
    };
  },
  validations() {
    const form = {
      form: {
        lastName: {
          required,
          notProfane: this.notProfane,
        },
        firstName: {
          required,
          notProfane: this.notProfane,
        },
        address1: {
          required,
          maxLength: maxLength(lineMaxLength),
        },
        address2: {
          maxLength: maxLength(lineMaxLength),
        },
        city: {
          required,
          maxLength: maxLength(cityMaxLength),
        },
        state: {
          required,
        },
        zipCode: {
          required,
          validZipcode: this.validZipcode,
        },
        sendDate: {
          required,
        },
      },
    };
    if (this.getSentiments.length) {
      form.form.sentiment = {
        required,
      };
    }
    if (this.showPersonalClosing) {
      form.form.closing = {
        required,
        notProfane: this.notProfane,
      };
    }
    if (this.accountNumberRequiresValidation) {
      let validations = {};
      if (this.accountNumberConfig.required) {
        validations = { ...validations, required };
      }
      if (this.accountNumberConfig.numeric) {
        validations = { ...validations, numeric: this.isNumeric };
      }
      if (this.accountNumberConfig.maxLength > 0) {
        validations = {
          ...validations,
          shortEnough: this.accountNumberIsShortEnough,
        };
      }
      form.form.accountNumber = validations;
    }
    return form;
  },
  computed: {
    ...mapGetters('front', [
      'getActiveProducts',
      'getMatchingProducts',
      'getMergedCategories',
      'getSentiments',
      'getMessageStarters',
      'getSenderProfiles',
      'getDefaultShipDate',
      'getClientConfig',
      'getCategoryCustomization',
      'getCustomerAccountNumberObject',
      'isFavorite',
    ]),
    ...mapGetters('ping', ['parsedIdToken']),
    ...mapState('front', [
      'activeCategory',
      'activeLanguage',
      'loading',
      'product',
      'order',
      'categories',
    ]),
    hasSenderProfile() {
      return this.senderProfile?.length > 0;
    },
    products() {
      return this.getActiveProducts;
    },
    //sentiment
    filteredProducts() {
      let normalizeInput = this.filterQuery.toLowerCase().trim();

      let addFavoritesAtt = this.getActiveProducts.map((p) => {
        return { ...p, isFavorite: this.isFavorite(p.product_id) };
      });
      let filteredFavs = addFavoritesAtt.filter((p) => p.isFavorite === true);
      let activeProducts = addFavoritesAtt.filter((p) => p.isFavorite != true);
      let productsList = [...filteredFavs, ...activeProducts];

      if (normalizeInput.length === 0) {
        return productsList;
      }

      return productsList.filter((product) => {
        let category = [...product.c_primaryCategory]
          .map((i) => {
            if (i === '-') {
              return ' ';
            }
            return i;
          })
          .join('');
        return (
          product.product_name.toLowerCase().trim().includes(normalizeInput) ||
          product.product_id.toLowerCase().trim().includes(normalizeInput) ||
          category.toLowerCase().trim().includes(normalizeInput)
        );
      });
    },
    categories() {
      let categoryList;
      categoryList = [
        {
          name: 'View All',
          id: this.$store.state.front.defaultCategory,
        },
        {
          name: 'Favorites',
          id: 'favorites',
        },
        ...this.getMergedCategories,
      ].map((category) => {
        const categoryCustomization = this.getCategoryCustomization;
        this.$set(
          category,
          'disabled',
          !this.hasProducts(category.id, this.activeLanguage)
        );
        if (category.id in categoryCustomization) {
          const englishName = categoryCustomization[category.id]?.english;
          const spanishName = categoryCustomization[category.id]?.spanish;
          if (englishName) {
            this.$set(category, 'name', englishName);
          }
          if (spanishName) {
            this.$set(category, 'spanishName', spanishName);
          }
        }
        return category;
      });
      return categoryList;
    },
    showSelectLanguage() {
      return (
        this.$store.getters['ping/parsedIdToken']?.userData?.languages?.length >
        1
      );
    },
    accountNumberConfig() {
      const accountNumber = this.getClientConfig?.customFields?.accountNumber;
      return {
        label: accountNumber?.label || 'Customer Account',
        enabled: accountNumber?.enabled || false,
        required: accountNumber?.required || false,
        numeric: accountNumber?.numeric || false,
        maxLength: accountNumber?.maxLength || 0,
      };
    },
    accountNumberRequiresValidation() {
      return (
        this.accountNumberConfig.enabled &&
        (this.accountNumberConfig.required ||
          this.accountNumberConfig.numeric ||
          this.accountNumberConfig.maxLength > 0)
      );
    },
    accountNumberErrorMessage() {
      const label = this.accountNumberConfig.label;
      if (
        this.accountNumberConfig.numeric &&
        !this.isNumeric(this.form.accountNumber)
      ) {
        return `${label} must be a number.`;
      }
      if (!this.accountNumberIsShortEnough(this.form.accountNumber)) {
        return `${label} can be no more than ${this.accountNumberConfig.maxLength} characters long.`;
      }
      if (this.accountNumberConfig.required) {
        return `${label} is required.`;
      }
      return '';
    },
    lastNameFeedback() {
      if (this.isProfane(this.form.lastName)) {
        return 'Please remove any possibly offending words.';
      }
      return 'Please enter a last name.';
    },
    firstNameFeedback() {
      if (this.isProfane(this.form.firstName)) {
        return 'Please remove any possibly offending words.';
      }
      return 'Please enter a first name.';
    },
    addressFeedback() {
      const length = this.$v.form.address1.$model.length;
      const lengthOver = length - lineMaxLength;
      return length > lineMaxLength
        ? `Address must be ${lineMaxLength} characters or less. Please remove ${lengthOver} character${
            lengthOver === 1 ? '' : 's'
          }.`
        : 'Please enter an address.';
    },
    address2Feedback() {
      const length = this.$v.form.address2.$model.length;
      const lengthOver = length - lineMaxLength;
      return length > lineMaxLength
        ? `Address line 2 must be ${lineMaxLength} characters or less. Please remove ${lengthOver} character${
            lengthOver === 1 ? '' : 's'
          }.`
        : '';
    },
    cityFeedback() {
      const length = this.$v.form.city.$model.length;
      const lengthOver = length - cityMaxLength;
      return length > cityMaxLength
        ? `City must be ${cityMaxLength} characters or less. Please remove ${lengthOver} character${
            lengthOver === 1 ? '' : 's'
          }.`
        : 'Please enter a city.';
    },
    closingFeedback() {
      if (this.isProfane(this.form.closing)) {
        return "We're unable to print your message as written. Please remove any possibly offending words.";
      }
      return 'Personal closing is required.';
    },
    senderProfileOptions() {
      return this.getSenderProfiles.map((profile) => {
        return {
          value: profile.profileID,
          text: profile.profileName,
        };
      });
    },
    selectedSenderProfile() {
      return this.getSenderProfiles.filter(
        (profile) => profile.profileID === this.senderProfile
      )[0];
    },
    sentimentOptions() {
      const sentiments = [
        ...this.getSentiments.map((sentiment) => ({
          value: sentiment.id,
          text: sentiment.description.default,
        })),
      ];
      if (sentiments.length > 1) {
        return [
          {
            value: null,
            text: 'Select a sentiment',
          },
          ...sentiments,
        ];
      }
      return sentiments;
    },
    presetClosingOptions() {
      return [
        {
          value: null,
          text: 'Choose personal closing',
        },
        ...this.getMessageStarters.map((closing) => ({
          value: closing.id,
          text: closing.c_hbc_MessageStarter,
          description: closing.description.default,
        })),
      ];
    },
    selectedSentiment() {
      if (!this.form.sentiment) {
        return null;
      }
      return (
        this.$store.state.front.libraryItems.find(
          (item) =>
            item.c_hbc_MessageType === 'sentiment' &&
            item.id === this.form.sentiment
        ) || null
      );
    },
    selectedClosing() {
      if (!this.presetClosing) {
        return null;
      }
      return this.$store.state.front.libraryItems.filter(
        (item) =>
          item.c_hbc_MessageType === 'messageStarter' &&
          item.id === this.presetClosing
      )[0];
    },
    productImage() {
      return (
        this.products.find((product) => this.product?.id === product.product_id)
          ?.image?.link || ''
      );
    },
    showPersonalClosing() {
      const disableEditPersonalClosing =
        this.$store.getters['front/getClientConfig']
          ?.disableEditPersonalClosing;
      return (
        this.presetClosingOptions.length > 1 ||
        disableEditPersonalClosing !== true
      );
    },
    disablePersonalClosing() {
      return (
        this.$store.getters['front/getClientConfig']
          ?.disableEditPersonalClosing === true
      );
    },
    cardSize() {
      return this.product.c_hbc_CardSize;
    },
    orientation() {
      return this.product.c_hbc_CardOrientation;
    },
    sentimentUrl() {
      return this.selectedSentiment?.c_hbc_SentimentImage?.abs_url || '';
    },
    logoUrl() {
      return null;
    },
    personalizationName() {
      return this.form.firstName;
    },
    signature() {
      const idToken = this.$store.getters['ping/parsedIdToken'];
      const replace = {
        '{{firstname}}': idToken.given_name,
        '{{lastname}}': idToken.family_name,
        '{{firstinitial}}': idToken.given_name.charAt(0),
        '{{lastinitial}}': idToken.family_name.charAt(0),
        '{{location}}': this.getClientConfig?.locations?.find(
          (location) => idToken?.profile?.location === location.value
        )?.label,
        '{{senderProfileName}}': this.selectedSenderProfile?.profileName,
      };
      let signature = this.selectedSenderProfile.signature;
      for (let key in replace) {
        if (!replace.hasOwnProperty(key)) {
          continue;
        }
        signature = signature.replace(new RegExp(key, 'g'), replace[key]);
      }
      return signature;
    },
    indentedSignature() {
      const indent = '\t\t\t\t\t';
      return `${indent}${this.signature.replace(
        new RegExp('\\n', 'g'),
        `\n${indent}`
      )}`;
    },
    shippingAddress() {
      const { firstName, lastName, address1, address2, city, state, zipCode } =
        this.form;
      return {
        first_name: firstName,
        last_name: lastName,
        address1,
        address2,
        city,
        state_code: state,
        postal_code: zipCode,
        country_code: 'US',
      };
    },
    billingAddress() {
      const { name, address1, address2, city, state, zip, country } =
        this.selectedSenderProfile.returnAddress;
      return {
        first_name: this.parsedIdToken?.given_name,
        last_name: this.parsedIdToken?.family_name,
        address1,
        address2,
        city,
        state_code: state,
        postal_code: zip,
        company_name: name,
        country_code: country,
      };
    },
    returnAddress() {
      const { name, address1, address2, city, state, zip, country } =
        this.selectedSenderProfile.returnAddress;
      return {
        ContentType: 'ReturnAddress',
        FirstName: this.$store.getters['ping/parsedIdToken']?.given_name,
        LastName: this.$store.getters['ping/parsedIdToken']?.family_name,
        Address1: address1,
        Address2: address2,
        City: city,
        State: state,
        PostalCode: zip,
        CompanyName: name,
        Country: country,
        FontSize: 21,
      };
    },
    recipientAddressForEnvelopePreview() {
      const { firstName, lastName, address1, address2, city, state, zipCode } =
        this.form;
      return [
        `${firstName} ${lastName}`,
        `${address1}`,
        `${address2}`,
        `${city}, ${state} ${zipCode}`,
      ]
        .filter((line) => {
          // remove empty lines
          const newLine = line.replace(/\W/g, '');
          return newLine !== '' && newLine !== 'null';
        })
        .join('\n');
    },
    returnAddressForEnvelopePreview() {
      if (this.selectedSenderProfile?.returnAddress) {
        const { name, address1, address2, city, state, zip } =
          this.selectedSenderProfile.returnAddress;
        return [
          `${name}`,
          `${address1}`,
          `${address2}`,
          `${city}, ${state} ${zip}`,
        ]
          .filter((line) => {
            // remove empty lines
            const newLine = line.replace(/\W/g, '');
            return newLine !== '' && newLine !== 'null';
          })
          .join('\n');
      } else {
        return '';
      }
    },
    message() {
      return [
        `${this.personalizationName},`,
        '\n\t',
        this.form.closing,
        '\n\n',
        this.indentedSignature,
      ].join('');
    },
    insideOfTheCardUrl() {
      return this.product.image_groups.filter(
        (group) => group.view_type === 'rs'
      )[0].images[0].link;
    },
    outsideOfTheCardUrl() {
      return this.product.image_groups.filter(
        (group) => group.view_type === 'fs'
      )[0].images[0].link;
    },
    enableBackCardContent() {
      if (this.$store.getters['front/getClientConfig']?.enableBackCardContent) {
        return true;
      }
      return false;
    },
    backCardMessage() {
      if (this.enableBackCardContent) {
        return (
          this.$store.getters['front/getClientConfig']?.backCardMessage || ''
        );
      }
      return null;
    },
    backCardLogo() {
      if (this.enableBackCardContent) {
        var defaultLogo =
          this.$store.state.ocapi.customerGroup?.c_clientLogo?.abs_url || null;
        var specificLogo = this.selectedSenderProfile?.specificLogo || null;
        if (specificLogo && defaultLogo) {
          return (
            defaultLogo.substring(0, defaultLogo.lastIndexOf('/')) +
            specificLogo.substring(specificLogo.lastIndexOf('/'))
          );
        }
        return defaultLogo;
      }
      return null;
    },
    backCardDisclaimer() {
      var defaultDisclaimer =
        this.$store.getters['front/getClientConfig']?.backCardDisclaimer ||
        null;
      var specificDisclaimer =
        this.selectedSenderProfile?.specificDisclaimer || null;
      if (this.product.c_hbc_CardLanguage == 'es') {
        return (
          specificDisclaimer?.es ||
          defaultDisclaimer?.es ||
          specificDisclaimer?.default ||
          defaultDisclaimer?.default ||
          null
        );
      } else {
        return (
          specificDisclaimer?.default || defaultDisclaimer?.default || null
        );
      }
    },
    orderConfirmationDetails() {
      if (this.order) {
        const deliveryAddress = this.order.shipments[0].shipping_address;
        return {
          orderNo: this.order.order_no,
          shipDate: this.order.shipments[0].c_req_ship_date,
          prodDesc: this.order.product_items[0].product_name,
          dAddline1: deliveryAddress.full_name,
          dAddline2: deliveryAddress.address1,
          dAddline3: deliveryAddress.address2,
          dAddline4:
            deliveryAddress.city +
            ', ' +
            deliveryAddress.state_code +
            ' ' +
            deliveryAddress.postal_code,
        };
      } else {
        return {};
      }
    },
    charactersRemaining() {
      var char = this.form.closing ? this.form.closing.length : 0,
        limit = 270;
      return limit - char + ' characters left';
    },
    zipCodeFeedback() {
      if (this.form.zipCode?.length) {
        return 'Please enter a valid zip code.';
      }
      return 'Please enter a zip code.';
    },
    taxClassCode() {
      const taxExempt = this.getClientConfig?.customFields?.taxExempt;
      if (
        taxExempt?.enabled &&
        taxExempt?.states?.includes(this.shippingAddress.state_code)
      ) {
        return taxExempt.code;
      }
      return '';
    },
  },
  methods: {
    ...mapActions('front', [
      'init',
      'setActiveCategory',
      'fetchProductInfo',
      'placeOrder',
      'updateOrderProgressBar',
    ]),
    ...mapActions('ocapi', ['fetchCustomerBaskets', 'deleteBasket']),
    ...mapMutations({
      startAppLoading: 'startLoading',
      stopAppLoading: 'stopLoading',
    }),
    ...mapMutations('front', ['startLoading', 'stopLoading']),
    ...mapActions('personalization', ['personalizeCard', 'previewEnvelope']),
    hasProducts(categoryId, languageId) {
      return this.getMatchingProducts(categoryId, languageId).length > 0;
    },
    handleNavChange(id) {
      this.setActiveCategory(id);
    },
    getProducts() {
      return this.getActiveProducts;
    },
    isNumeric(val) {
      return val.length === 0 || numbersOnlyRegex.test(val);
    },
    isShortEnough(val, maxLength) {
      if (maxLength && maxLength > 0) {
        return val.length <= maxLength;
      }
      return true;
    },
    accountNumberIsShortEnough(val) {
      return this.isShortEnough(val, this.accountNumberConfig.maxLength);
    },
    isProfane(value) {
      return profanityFilter.isProfane(value);
    },
    notProfane(value) {
      return !this.isProfane(value);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    dateDisabled(ymd, date) {
      // exclude weekends
      if (date.getDay() === 0 || date.getDay() === 6) {
        return true;
        // too far in the past
      } else if (this.minDate.getTime() > date.getTime()) {
        return true;
        // too far in the future
      } else if (this.maxDate.getTime() < date.getTime()) {
        return true;
        // explicitly excluded
      } else if (
        this.$store.getters['front/getBlackoutDates'].indexOf(ymd) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    },
    onOrderFormHidden() {
      if (this.storeRecipientData) {
        const {
          accountNumber,
          firstName,
          lastName,
          address1,
          address2,
          city,
          state,
          zipCode,
          sendDate,
        } = this.form;

        Cookies.set(
          'recipientData',
          JSON.stringify({
            accountNumber,
            firstName,
            lastName,
            address1,
            address2,
            city,
            state,
            zipCode,
            sendDate,
          })
        );
      }
      this.resetForm();
      this.$store.commit('front/resetOrderProgressBar');
    },
    checkBaskets() {
      return new Promise((resolve, reject) => {
        const fetchCustomerBaskets = this.fetchCustomerBaskets();
        fetchCustomerBaskets
          .then((baskets) => {
            if (baskets.length >= maxBaskets) {
              return this.confirmDeleteBaskets(baskets);
            }
            return Promise.resolve();
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    confirmDeleteBaskets(baskets) {
      this.stopLoading();
      return new Promise((resolve, reject) => {
        this.$bvModal
          .msgBoxConfirm(
            "It appears you have an order in progress in another window or you didn't complete an order previously. Do you want to cancel those orders to send this one?",
            {
              okVariant: 'danger',
              okTitle: 'Yes',
              cancelTitle: 'No',
              hideHeaderClose: false,
              centered: true,
              noCloseOnBackdrop: true,
              noCloseOnEsc: true,
            }
          )
          .then((value) => {
            if (value) {
              this.startLoading();
              const deleteRequests = [];
              baskets.forEach((basket) => {
                deleteRequests.push(this.deleteBasket(basket.basket_id));
              });
              return Promise.all(deleteRequests);
            }
            reject();
          })
          .then(resolve)
          .catch(reject);
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.startLoading();
      this.checkBaskets()
        .then(() => {
          this.stopLoading();
          this.doOrder();
        })
        .catch((error) => {
          console.error(error);
          this.stopLoading();
        });
    },
    doOrder() {
      let currentCategoryObj = this.categories.filter(
        (category) => category.id == this.product.primary_category_id
      );
      let currentCategory =
        this.product.c_hbc_CardLanguage == 'es'
          ? currentCategoryObj[0].spanishName
          : currentCategoryObj[0].name;
      this.placeOrder({
        personalization: {
          render: 'print',
          cardSize: this.cardSize,
          orientation: this.orientation,
          personalization: [
            {
              sideOfCard: 'outside',
              pageTemplate: this.outsideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.backCardLogo,
                  },
                ],
                messages: [
                  {
                    text: this.backCardMessage,
                    font: 'garamond10Center',
                  },
                  {
                    text: this.backCardDisclaimer,
                    font: 'whitneybook11Center',
                  },
                ],
                barcodes: [
                  {
                    text: this.$store.state.front.recipient.scanCode,
                  },
                ],
              },
            },
            {
              sideOfCard: 'inside',
              pageTemplate: this.insideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.sentimentUrl,
                  },
                  {
                    url: this.logoUrl,
                  },
                ],
                messages: [
                  {
                    text: this.message,
                    font: 'johnson14Left',
                  },
                ],
              },
            },
          ],
        },
        returnAddress: this.returnAddressForEnvelopePreview,
        recipientAddress: this.recipientAddressForEnvelopePreview,
        item: {
          product_id: this.product.id,
          c_ax_item_id: this.product.c_hbc_AxItemID,
          c_ax_envelope_id: this.product.c_hbc_AXIDEnvelope,
          c_hbc_PersonalizationObject: JSON.stringify({
            sampleJSON: 'sample',
          }),
          c_sentiment: this.selectedSentiment?.description?.default || '',
          c_sentimentCode: this.selectedSentiment?.id || '',
          c_personalizationName: this.personalizationName,
          c_hbc_personalizationMessage: this.form.closing,
          c_signature: this.signature,
          c_envelopeFontFamily: 'JohnsonPrint',
          c_envelopeFontColor: '#000000',
          c_productCategory: currentCategory,
          c_productLanguage: this.product.c_hbc_CardLanguage,
          c_hbc_returnAddress: JSON.stringify(this.returnAddress),
          c_scanCode: this.$store.state.front.recipient.scanCode,
          c_hbc_FulfillmentCenterId: this.product.c_hbc_ShipNode,
        },
        order: {
          shipping_address: this.shippingAddress,
          billing_address: this.billingAddress,
          sender_profile: this.selectedSenderProfile,
          c_req_ship_date: this.form.sendDate,
          c_customerAccountNo: JSON.stringify(
            this.getCustomerAccountNumberObject(this.form.accountNumber)
          ),
          c_taxClassCode: this.taxClassCode || '',
          location: this.$store.getters['ping/parsedIdToken'].profile.location,
          employeeID:
            this.$store.getters['ping/parsedIdToken'].profile.employeeID,
          supervisorID:
            this.$store.getters['ping/parsedIdToken'].profile.supervisorID,
        },
      })
        .then(() => {
          Cookies.remove('recipientData');
          this.storeRecipientData = false;
          let autoLogout =
            this.$store.getters['front/getClientConfig']?.singleSendSSO &&
            this.$store.getters['ping/parsedIdToken'].recipientAddress1;
          this.$store.dispatch('ping/updateUser', {
            recipientAddress1: null,
            recipientAddress2: null,
            recipientCity: null,
            recipientState: null,
            recipientPostalCode: null,
            recipientCountry: null,
            recipientFirstName: null,
            recipientLastName: null,
            accountNumber: null,
          });
          const recipient = {
            customer_id: '',
            scanCode: '',
            shipping_address: {
              address1: '',
              address2: '',
              city: '',
              company_name: '',
              country_code: '',
              first_name: '',
              last_name: '',
              phone: '',
              postal_code: '',
              state_code: '',
            },
          };
          this.$store.commit('front/setRecipient', recipient);
          this.showConfirmation = true;
          this.showForm = false;
          //track purchase
          this.$gtm.trackEvent({
            event: 'purchase',
            transaction_id: this.order.order_no,
            affiliation: this.$store.state.ocapi.customerGroup.description,
            value: this.order.order_total,
            currency: 'USD',
            tax: this.order.tax_total,
            shipping: this.order.shipping_total,
            items: [
              {
                item_id: this.order.product_items[0].product_id,
                item_name: this.order.product_items[0].product_name,
                item_category:
                  this.$store.state.front.activeCategory === 'root'
                    ? 'view-all'
                    : this.$store.state.front.activeCategory,
                quantity: this.order.product_items[0].quantity,
                price: this.order.product_items[0].price_after_order_discount,
              },
            ],
          });

          //Logging them off if Single Send SSO was turned on in Client Config and they
          //came over to us with contact info in their SAML assertion
          if (autoLogout) {
            setTimeout(function () {
              store.dispatch('ping/doLogout');
            }, 5000);
          }
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit('front/resetOrderProgressBar');
        });
    },
    initializeForm() {
      let accountNumber = this.$store.state.front.recipient.customer_id;
      let {
        first_name,
        last_name,
        address1,
        address2,
        city,
        state_code,
        postal_code,
      } = this.$store.state.front.recipient.shipping_address;
      let sendDate = this.getInitialSendDate();
      let disabledFields = [];
      let form = {
        accountNumber: '',
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        sentiment: this.sentimentOptions.length
          ? this.sentimentOptions[0].value
          : '',
        closing: null,
        sendDate: sendDate,
      };
      var data = Cookies.get('recipientData');
      if (data != null) {
        data = JSON.parse(data);
        form.accountNumber = data.accountNumber;
        form.firstName = data.firstName;
        form.lastName = data.lastName;
        form.address1 = data.address1;
        form.address2 = data.address2;
        form.city = data.city;
        form.state = data.state;
        form.zipCode = data.zipCode;
        form.sendDate = data.sendDate;
      }

      let formValuesMap = [
        {
          prop: 'accountNumber',
          val: accountNumber,
        },
        {
          prop: 'firstName',
          val: first_name,
        },
        {
          prop: 'lastName',
          val: last_name,
        },
        {
          prop: 'address1',
          val: address1,
        },
        {
          prop: 'address2',
          val: address2,
        },
        {
          prop: 'city',
          val: city,
        },
        {
          prop: 'state',
          val: state_code,
        },
        {
          prop: 'zipCode',
          val: postal_code,
        },
      ];
      formValuesMap.forEach((field) => {
        if (field.val) {
          if (field.prop == 'zipCode' && field.val.length > 5) {
            form[field.prop] = this.formatZipcode(field.val);
          } else {
            form[field.prop] = field.val;
          }
          //disabledFields.push(field.prop);
        }
      });
      if (this.getDefaultShipDate) {
        disabledFields.push('sendDate');
      }
      this.form = form;
      this.disabledFields = disabledFields;
    },
    isDisabledField(field) {
      return this.disabledFields.indexOf(field) !== -1;
    },
    handlePresetClosingChange() {
      this.form.closing = this.selectedClosing?.c_hbc_MessageStarter || '';
    },
    openForm(product_id) {
      this.startLoading();
      this.storeRecipientData = true;
      this.fetchProductInfo(product_id)
        .then(() => {
          this.initializeForm();
          this.showForm = true;
          this.stopLoading();
        })
        .catch((error) => {
          this.stopLoading();
          console.error(error);
        });
    },
    resetForm() {
      this.startLoading();

      this.form = {
        accountNumber: '',
        lastName: '',
        firstName: '',
        address1: '',
        address2: '',
        sentiment: null,
        closing: null,
        city: '',
        state: '',
        zipCode: '',
        sendDate: '',
      };
      this.presetClosing = null;

      // this.initializeForm();
      this.$nextTick(() => {
        this.$v.$reset();
        this.stopLoading();
      });
    },
    openPreview() {
      this.startLoading();
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        console.log('error');
        this.stopLoading();
        return;
      }
      let previewImages = [];
      // front of card - Disabled to use the image returned by api
      /* const frontOfCard = this.product.image_groups.filter(
        (group) => group.view_type === "large"
      )[0].images[0].link; */
      let frontOfCard, insideOfCard, backOfCard, envelope;
      Promise.all([
        this.personalizeCard({
          render: 'preview',
          cardSize: this.cardSize,
          orientation: this.orientation,
          bleed: 46,
          singlePanelCrop: true,
          personalization: [
            {
              sideOfCard: 'inside',
              pageTemplate: this.insideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.sentimentUrl,
                  },
                  {
                    url: this.logoUrl,
                  },
                ],
                messages: [
                  {
                    text: this.message,
                    font: 'johnson14Left',
                  },
                ],
                barcodes: [],
              },
            },
          ],
        }).then((response) => {
          insideOfCard = response.data.jpegDetails?.imageUrl;
          return Promise.resolve();
        }),
        this.personalizeCard({
          render: 'preview',
          cardSize: this.cardSize,
          orientation: this.orientation,
          bleed: 46,
          GetSurfaceImages: true,
          personalization: [
            {
              sideOfCard: 'outside',
              pageTemplate: this.outsideOfTheCardUrl,
              rightOrBottomOfPage: {
                images: [
                  {
                    url: this.backCardLogo,
                  },
                ],
                messages: [
                  {
                    text: this.backCardMessage,
                    font: 'garamond10Center',
                  },
                  {
                    text: this.backCardDisclaimer,
                    font: 'whitneybook11Center',
                  },
                ],
                barcodes: [
                  {
                    text: this.$store.state.front.recipient.scanCode,
                  },
                ],
              },
            },
          ],
        }).then((response) => {
          frontOfCard = response.data.primaryJpegDetails?.imageUrl;
          backOfCard = response.data.secondaryJpegDetails?.imageUrl;
          return Promise.resolve();
        }),
        this.previewEnvelope({
          returnAddress: this.returnAddressForEnvelopePreview,
          recipientAddress: this.recipientAddressForEnvelopePreview,
        }).then((response) => {
          envelope = response.data.jpegDetails.imageUrl;
        }),
      ])
        .then(() => {
          previewImages = [frontOfCard, insideOfCard, backOfCard, envelope];
          this.previewImages = previewImages;
          this.showPreview = true;
          this.stopLoading();
        })
        .catch((error) => {
          this.stopLoading();
          console.error(error);
        });
    },
    close() {
      this.$emit('close');
    },
    toggleFavorite(product_id) {
      this.$store.dispatch('ping/toggleFavorite', product_id);
    },
    setInitialSenderProfile() {
      if (this.senderProfileOptions.length) {
        this.senderProfile = this.senderProfileOptions[0].value;
      } else {
        this.senderProfile = null;
      }
    },
    getFormattedDateString(date) {
      // add leading zeros
      const month = `0${date.getMonth() + 1}`.slice(-2);
      const day = `0${date.getDate()}`.slice(-2);
      return `${date.getFullYear()}-${month}-${day}`;
    },
    getInitialSendDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      let initialDate = today;

      const defaultDate = this.getDefaultShipDate;
      if (
        defaultDate &&
        defaultDate.getTime() >= this.minDate.getTime() &&
        this.maxDate.getTime() >= defaultDate.getTime()
      ) {
        initialDate = defaultDate;
      }
      if (
        !this.dateDisabled(
          this.getFormattedDateString(initialDate),
          initialDate
        )
      ) {
        return this.getFormattedDateString(initialDate);
      } else {
        // increment by 1 day until not disabled
        while (
          this.dateDisabled(
            this.getFormattedDateString(initialDate),
            initialDate
          )
        ) {
          initialDate.setDate(initialDate.getDate() + 1);
        }
        return this.getFormattedDateString(initialDate);
      }
    },
    setMinMaxDates() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      // today
      const minDate = new Date(today);
      // minDate.setDate(minDate.getDate() + 1)
      // one month ahead
      const maxDate = new Date(today);
      maxDate.setDate(maxDate.getDate() + 45);
      this.minDate = minDate;
      this.maxDate = maxDate;
    },
    formatZipcode(value) {
      if (
        (value.length > 5 && !hyphenZipCodeStartRegex.test(value)) ||
        !numbersOnlyRegex.test(value)
      ) {
        // extract all numbers
        let newValue = value.replace(/\D/g, '');
        if (newValue.length > 5) {
          // max 9 numbers
          if (newValue.length > 9) {
            newValue = `${newValue.slice(0, 9)}`;
          }
          // add hyphen
          newValue = [newValue.slice(0, 5), '-', newValue.slice(5)].join('');
        }
        this.$nextTick(() => {
          this.form.zipCode = newValue;
        });
      }
    },
    validZipcode(value) {
      return (
        (value.length === 5 && numbersOnlyRegex.test(value)) ||
        hyphenZipCodeRegex.test(value)
      );
    },
  },
  mounted() {
    if (!this.$store.state.ocapi.customerGroup) {
      this.startAppLoading();
    }
    this.init()
      .then(() => {
        this.setInitialSenderProfile();
        this.setMinMaxDates();
      })
      .catch((error) => {
        this.$store.dispatch('handleError', {
          title: 'Could not initialize',
          message:
            'An error occurred while initializing products and categories',
          data: error,
        });
      });
    if (this.$store.getters['front/getClientConfig']?.singleSendSSO) {
      window.onbeforeunload = function () {
        store.dispatch('ping/doLogout');
      };
    }
    window.history.replaceState(null, null, window.location.pathname);
  },
  watch: {
    senderProfileOptions: {
      handler: function (newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.setInitialSenderProfile();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '../scss/variables';

.results {
  text-align: right;
  color:  $font-base;

  @media (max-width: $phone-width) {
    text-align: center;
  }
}

.no-results {
  color:  $font-base;
}

.care-container {
  display: flex;
  flex-direction: row;
  max-width: calc(100% - 10vw);
  margin: auto;

  @media (max-width: $phone-width) {
    max-width: max-content;
    flex-direction: column;
  }
}

.card-drawer {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  margin-top: 1.2rem;

  @media (max-width: $phone-width) {
    margin-top: 0.6rem;
    gap: 0.6rem;
  }
}

.card-container {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.6rem;
  grid-template-columns: repeat(4, minmax(180px, 300px));
  list-style-type: none;

  @media (max-width: 1120px) {
    gap: 1.2rem;
    grid-template-columns: repeat(3, minmax(180px, 240px));
    list-style-type: none;
    padding-inline-start: 0;
  }

  @media (max-width: $tablet-width) {
    display: grid;
    padding: 0;
    gap: 0.8rem;
    grid-template-columns: repeat(2, minmax(160px, 180px));
    list-style-type: none;
  }
}

.light-bg {
  background-color: $base-light;
}

.form-control.is-invalid ~ .input-group-append .input-group-text {
  border-color: var(--danger);
}

.form-group {
  margin-bottom: 10px;

  .limiter {
    color: $font-base;
    float: right;
  }

  textarea {
    margin-bottom: 0px;
  }
}

.form-control.is-valid ~ .input-group-append .input-group-text {
  border-color: var(--success);
}

::v-deep .modal-fullscreen {
  padding: 0 !important;
}

::v-deep .modal-fullscreen .modal-header {
  border-radius: 0 !important;
  border-color: transparent;
  background-color: transparent;
}

::v-deep .modal-fullscreen .modal-dialog {
  max-width: 100%;
  height: 100%;
  margin: 0;
}

::v-deep .modal-fullscreen .modal-body {
  background-color: transparent;

  @media (max-width: $tablet-width) {
    padding: 1rem 0;
  }
}

::v-deep .modal-fullscreen .modal-content {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  min-height: 100%;
  height: auto;
}

::v-deep .modal-fullscreen + .modal-backdrop {
  opacity: 0.8;
}

::v-deep .modal-preview .carousel-item {
  // height of all other vertical spacing elements in modal
  // h2 = 1.25rem * 1.5
  // header padding = 1rem * 2
  // header border width = 1px * 2
  // body padding = 1rem * 2
  // body bottom border width = 1px
  // modal vertical margin = 1.75rem * 2
  height: calc(90vh - (#{((1.25rem * 1.5) + 7.5rem)} + 3px));
  text-align: center;

  img {
    max-width: 100%;
    width: auto !important;
    //IE 11 fixes
  }
}

::v-deep .modal-preview .carousel-indicators {
  margin-bottom: 5px;
}

::v-deep .modal-preview .carousel-item .carousel-caption {
  color: #000;
  top: 10px;
  right: 10px;
  left: 10px;
  height: calc(90vh - (#{((1.25rem * 1.5) + 7.5rem)} + 3px));
  width: calc(60vw - (#{((1.25rem * 1.5) + 7.5rem)} + 3px));
}

::v-deep .modal-header {
  background-color: $base-white;
  color: $font-base;
  border-bottom: $base-white;
  font: $font-size-20;
}

::v-deep .modal-body {
  background-color: $base-white;
}

::v-deep .carousel-control-next,
::v-deep .carousel-control-prev {
  width: 10%;
  z-index: 99;
}

::v-deep .carousel-control-next-icon,
::v-deep .carousel-control-prev-icon {
  background-color: #000;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-size: 60%;
  background-position: center;
}

::v-deep .carousel-item.envelopeImage {
  text-align: center;

  img {
    object-fit: fill;
    max-width: 100%;
  }

  .carousel-caption {
    margin: auto;
    width: 100%;
    max-width: 840px;
    text-shadow: none !important;
    vertical-align: middle;
    top: 10px;
    bottom: 10px;
    max-height: calc(75vw * 0.65);
    height: 100%;
    font-size: calc(10px + 5 * ((100vw - 320px) / 680));
  }
}

::v-deep .modal-footer {
  background-color: $base-white;

  .btn-secondary {
    background-color: $base-white;
    color: $base-black;
    border: 1px solid $base-black;
    border-radius: 2px;
    font: $font-size-20;
    padding: 0.6rem 2.8rem;

    @media (max-width: $phone-width) {
      padding: 0.6rem 1.2rem;
    }
    &.active {
      background-color: $base-light;
      border: transparent;
      transition: ease 0.3s;
    }
  }

  .btn-primary {
    background-color: $base-yellow;
    border: $base-yellow;
    border: 1px solid $base-yellow;
    border-radius: 2px;
    color: #373534;
    font: $font-size-20;
    padding: 0.6rem 2.8rem;
    @media (max-width: $phone-width) {
      padding: 0.6rem 1.2rem;
    }
  }
}

.language-selector {
  @media (max-width: $phone-width) {
    display: none;
  }
}

.order-confirmation {
  h4 {
    margin-bottom: 0;
  }

  .row {
    margin-bottom: 0.5rem;
  }

  .form-control {
    height: unset;
  }
}

.search-form {
  display: flex;
  border: none;
  border-radius: 2px;
  background-color: $base-white;

  @media (max-width: $phone-width) {
    display: none;
  }

  &:focus-within {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .searchbar {
    font: normal normal 600 14px/18px Montserrat;
    color: $base-black;
    padding: 4px 8px;
    border: 0;
    background-color: $base-white;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .search {
    cursor: text;
    background-color: $base-white;

    :focus {
      box-shadow: none;
    }

    img {
      color: $base-black;
    }
    svg {
      color: $base-black;
      width: 18px;
    }
  }

  button {
    border: 0;
    padding: 2px 10px;
    font-weight: 700;
    background-color: white;

    img {
      width: 1rem;
    }
  }
}

.preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;

  h2 {
    font: $font-size-16;
    font-weight: bold;
    color: $base-black;
  }

  .close {
    background: transparent;
    color: $base-black;
    font-size: 200;
  }
}

.sender-form {
  background-color: $base-white;
  border-radius: 3px;
  padding: 0;
  overflow: hidden;
  width: clamp(720px, 40% + 20vw, 970px);
  margin: auto;
  margin-top: 1.2rem;

  @media (max-width: $tablet-width) {
    width: 98vw;
    margin: 0 1.2rem 0 0;
  }

  @media (max-width: $phone-width) {
    margin: 0 1.2rem 0 0;
  }
  h2 {
    font: $font-size-21;
    color: $base-black;
  }

  h3 {
    font: $font-size-16;
    font-weight: bold;
    color: $base-black;
    padding-left: 1.2rem;
    padding-top: 0.6rem;
  }

  .sender-header {
    background-color: $base-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.2rem;

    .close {
      background: transparent;
      color: $base-black;
      font-size: 200;
    }
  }

  .header {
    background-color: $base-light;
    display: flex;
    align-items: center;
    padding: 0.6rem 1.2rem;
  }

  .sender-grid {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    column-gap: 0.6rem;
    padding: 0.6rem 1.2rem;

    @media (max-width: $phone-width) {
      display: flex;
      flex-direction: column;
    }

    label {
      font: $font-size-16;
      color: $base-black;
      font-weight: bold;
      margin: 0;
    }

    select,
    input {
      border: 1px solid $base-grey;
      border-radius: 3px;
    }

    .profile {
      grid-column: span 4;
      @media (max-width: $tablet-width) {
        grid-column: span 6;
      }
    }

    .customer-num {
      grid-column: span 2;
      @media (max-width: $tablet-width) {
        grid-column: span 3;
      }
    }

    .first-name {
      grid-column: span 3;
      @media (max-width: $tablet-width) {
        grid-column: span 4;
      }
    }

    .last-name {
      grid-column: span 3;
      @media (max-width: $tablet-width) {
        grid-column: span 4;
      }
    }

    .address-one {
      grid-column: span 4;
      // @media (max-width: 740px) {
      //   grid-column: span 3;
      // }
    }

    .address-two {
      grid-column: span 2;
      @media (max-width: $tablet-width) {
        grid-column: span 3;
      }
    }

    .city {
      grid-column: span 2;
      @media (max-width: $tablet-width) {
        grid-column: span 3;
      }
    }

    .state {
      grid-column: span 2;
      @media (max-width: $tablet-width) {
        grid-column: span 3;
      }
    }

    .postal-code {
      grid-column: span 2;
      @media (max-width: $tablet-width) {
        grid-column: span 3;
      }
    }
  }

  .personalize-grid {
    display: grid;
    // grid-template-columns: repeat(1fr);
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    gap: 0.6rem;
    padding: 0.6rem 1.2rem;
    grid-auto-rows: 1fr;

    @media (max-width: $phone-width) {
      display: flex;
      flex-direction: column;
    }

    label:nth-child(3n + 1) {
      grid-column: 1;
    }

    label:nth-child(3n + 2) {
      grid-column: 2;
    }

    label:nth-child(3n + 3) {
      grid-column: 3;
    }

    @media (max-width: $tablet-width) {
      label:nth-child(2n + 1) {
        grid-column: 1;
      }

      label:nth-child(2n + 2) {
        grid-column: 2;
      }
    }

    label {
      display: flex;
      padding: 0.4rem;
      border: 1px solid $base-grey;
      border-radius: 3px;
      background-color: $bg-label;
      text-align: left;
      font: $font-size-15;
      color: $base-black;
      cursor: pointer;

      &.active {
        background-color: $base-light;
        color: $highlight2;
        border: 1px solid $border3;
        transition: ease 0.3s;
      }

      input {
        display: none;
      }
    }
  }

  .account-error {
    display: flex;
    flex-direction: column;
    padding: 0.4rem 1.2rem;

    h4,
    p {
      font: $font-size-16;
    }

    h4 {
      font-weight: 700;
    }
  }

  .personal-closing {
    padding: 0 1.2rem;
  }

  .invalid-sentiment {
    padding: 0 1.2rem;
    div {
      margin-top: none;
    }
  }

  .send-date {
    padding: 0.4rem 1.2rem;
    margin-bottom: 0.4rem;
    .hidden {
      opacity: 0;
    }
    .display {
      opacity: 1;
      transition: ease 0.3s;
    }
    label,
    span {
      font: $font-size-15;
      color: $base-black;
    }

    span {
      opacity: 1;
    }
  }

  .sender-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0 1.2rem;
    column-gap: 0.6rem;
    padding-bottom: 1.2rem;

    @media (max-width: $phone-width) {
      display: flex;
      flex-direction: column;
      gap: 0.6rem;
    }

    button {
      background-color: $base-white;
      color: $base-black;
      border: 1px solid $base-black;
      border-radius: 2px;
      font: $font-size-20;
      padding: 0.6rem;

      &.active {
        background-color: $base-light;
        border: transparent;
        transition: ease 0.3s;
      }
    }

    button:nth-of-type(3) {
      color: #373534;
      background-color: $base-yellow;
      border: $base-yellow;
    }
  }
}
.image-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.image-preview {
  background-color: $base-light;
  img {
    aspect-ratio: 1/1;
    padding: 3rem;

    @media (max-width: $phone-width) {
      padding: 2rem;
    }
  }
}
</style>
